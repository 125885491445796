<template>
  <div>
    <h1>My Cases</h1>
    <hr>

    <div class="main-tab">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('CASE_LEVEL')"
        class="mr-1"
        @click.prevent="openMainTab('CASE_LEVEL')"
      >
        Case Level
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('ACTION_LEVEL')"
        class="mr-1"
        @click.prevent="openMainTab('ACTION_LEVEL')"
      >
        Action Level
      </b-button>
    </div>

    <div
      v-if="mainTab === 'CASE_LEVEL'"
      class="custom-p-2 pt-1"
    >
      <CaseLevel />
    </div>

    <div
      v-if="mainTab === 'ACTION_LEVEL'"
      class="custom-p-2 pt-1"
    >
      <ActionLevel />
    </div>

  </div>
</template>

<script>
import {BButton} from "bootstrap-vue";
import CaseLevel from "@/views/mycases/CaseLevel";
import ActionLevel from "@/views/mycases/ActionLevel";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ActionLevel,
    CaseLevel,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      mainTab: "CASE_LEVEL",
    }
  },
  methods: {
    openMainTab(tab) {
      this.mainTab = tab
    },
    btnVariant(tab) {
      return this.mainTab === tab ? "primary" : "none"
    },
  }
}
</script>

<style scoped>

</style>